@import "./material";
@import 'swiper/scss';

html, body {
  height: 100%;
  background: #F3F3F3;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  color: #545455;
}

.mat-mdc-icon-button.mini-icon-button {
  height: 30px;
  width: 30px;
  padding: 0;

  mat-icon {
    line-height: 30px;
    font-size: 18px;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.app-dialog {
  width: 100%;
  max-width: 500px;
  margin: 0 10%;
}

.app-dialog-second {
  width: 100%;
  max-width: 400px;
  margin: 0 10%;
}

.app-dialog-large {
  width: 100vw;
  height: 100vh;
  padding: 50px;
  max-width: none !important;
  box-sizing: border-box;
}

.app-backdrop-black {
  background: rgba(0, 0, 0, 0.7);
}
