@use '@angular/material' as mat;
@use "sass:map";

@import "colors";

@include mat.core();

$app-typography: mat.define-typography-config(
  $font-family: 'Nunito Sans, sans-serif',
  $button: mat.define-typography-level(14px, 16px, 400, $letter-spacing: normal),
  $body-1: mat.define-typography-level(14px, 24px, 400, $letter-spacing: normal),
);

$app-theme: map.deep-merge(
    mat.define-light-theme((
      color: (
        primary: $app-primary,
        accent: $app-accent,
        warn: $app-danger,
      ),
      typography: $app-typography,
    )),
    (
      color: (
        background: (
          background: #F3F3F3,
        ),
        foreground: (
          base: #545455,
          text: #545455,
          elevation: #ccc,
        ),
      ),
    ),
);

@include mat.typography-hierarchy($app-typography);
@include mat.all-component-themes($app-theme);

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #545455;
}

.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-family: 'Open Sans', sans-serif !important;
  color: #545455 !important;
}

.mat-mdc-dialog-actions {
  justify-content: end !important;
  padding: 0 20px 20px !important;
}

.mat-mdc-option {
  --mdc-theme-text-primary-on-background: #545455;
}
